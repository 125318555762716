
import { AnnouncementApi } from '@/api/AnnouncementApi';
import AnnouncementsListCard from '@/components/announcement/AnnouncementsListCard.vue';
import PublishAnnouncementCard from '@/components/announcement/PublishAnnouncementCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { Announcement, Bot } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  name: 'project-content-announcements',
  components: { AnnouncementsListCard, PublishAnnouncementCard },
})
export default class ProjectContentAnnouncements extends mixins(ProjectViewMixin) {
  loadedAnnouncements: Announcement[] = [];

  @Watch('bot', { immediate: true })
  async onBotChange(bot: Bot) {
    await this.loadAnnouncements(bot);
  }

  async loadAnnouncements(bot: Bot) {
    this.loadedAnnouncements = await AnnouncementApi.loadBotAnnouncements(bot.id);
  }
}
